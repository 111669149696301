import React, { Component } from 'react';
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'
import {observer} from 'mobx-react'
import {StorworkInit, SingleStore} from 'storwork-stores'
import {Input} from 'storwork-components'
import getStore from './Store'

const SingleElement = props => {
  const { id, type, json } = props.element
  const { name } = JSON.parse(json)
  const linkBases = {
    spreadsheet: 'https://spreadsheet.storwork.pl/',
    article: 'https://writer.storwork.pl/',
    process: 'https://bpmn.storwork.pl/'
  }
  const link = linkBases[type] + id
  return <li><a href={link} target='_blank'>
    {name} - {type}
  </a></li>
}
const CreateType = props => <div className='button is-primary' onClick={() => {
  props.store.object.type = props.type
  props.store.sync().then(() => {
    window.location.reload()
  })
}}>
  {props.children}
</div>
const Creator = observer(props => {
  const StoreInput = Input(props.store)
  return <div className='container'><div className='columns'>
    <div className='column is-5'>
      <StoreInput placeholder='Nazwa...' className='input' path='object.json' json='name' />
    </div>
    <div className='column'>
      <CreateType type='spreadsheet' store={props.store}>Utwórz spreadsheet</CreateType>&nbsp;
      <CreateType type='process' store={props.store}>Utwórz proces</CreateType>&nbsp;
      <CreateType type='article' store={props.store}>Utwórz artykuł</CreateType>
    </div>
  </div></div>
})

const Application = observer(props => <div>
  <nav className="navbar is-fixed" role="navigation" aria-label="main navigation" style={{border: '1px solid rgba(0,0,0,0.2)'}}>
    <div className="navbar-brand">
      <span class="navbar-item" style={{fontWeight: 'bold'}}>
        Knowledge sharing
      </span>
    </div>
  </nav>
  <aside class="menu">
    <ul class="menu-list">
      {props.store.records.map(el => <SingleElement element={el} />)}
    </ul>
  </aside>
  <hr />
  <Creator store={new SingleStore({
      type: 'generalobject',
      defaultObject: {
        type: '',
        json: '{}'
      },
      entities: [{
        path: '',
        alwaysSave: true,
        fields: ['json', 'type'],
        type: 'GeneralObject',
        multi: false
      }]
    })} />
</div>)

const ApplicationWithStore = props => <Application store={getStore()} />

class App extends Component {
  render() {
    return <StorworkInit loggedIn={<ApplicationWithStore />} />
  }
}

export default App;
