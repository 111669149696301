import { ListStore } from 'storwork-stores'

let store = null

export default () => {
  if (!store) {
    store = new ListStore({type: 'generalobject', staticFilters: {
      type: [
        'spreadsheet', 'article', 'process'
      ]
    }})
  }
  return store
}
